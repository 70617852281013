/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      // Preloader
      $(window).on('load', function () {
        if ($(".pre-loader").length > 0) {
            $(".pre-loader").fadeOut(450);
        }
      });

      //frame page on scroll
        var nav, winHeight, ourTeamBtn;

        function paneScroll(){
            paneWindow = $('.cd-panel--is-visible .cd-panel__container');
            panePos = paneWindow.offset();
            if(panePos > 15){
              paneWindow.addClass("scroll-thee");
            }
            else {
              paneWindow.removeClass("scroll-thee");
            }
        }
        window.addEventListener("scroll", paneScroll);

        function yScroll(){
            nav = $('.banner');
            winHeight = $(window).height();
            ourTeamBtn = $('.btn-wrap.our-team');
            yPos = window.pageYOffset;
            nav.removeClass("navbar-fixed-top");
            if(yPos > 25){
              $("body").addClass("scrollme");
              $(".nav-primary").addClass("shrivel");
              nav.addClass("autobots rollout");
            }
            else {
              $('body').removeClass("scrollme");
              $(".nav-primary").removeClass("shrivel");
              nav.removeClass("autobots rollout");
              if(ourTeamBtn.hasClass('visible')){
                ourTeamBtn.removeClass("visible").addClass('visible-scd');
              }
            }
            if(yPos > (winHeight - 100) && ourTeamBtn.hasClass('visible-scd')){
              ourTeamBtn.addClass('visible-again');
            }
            else {
              ourTeamBtn.removeClass('visible-again');
            }
        }
        window.addEventListener("scroll", yScroll);

        //smoothScroll to top
        $('#to-top').click(function(event){
          event.preventDefault();
          $('html, body').animate({
            scrollTop:0
          },'5000');
        });

        //smoothScroll to top
        $('.home .brand').click(function(event){
          event.preventDefault();
          $('html, body').animate({
            scrollTop:0
          },'5000');
        });

        $('#team-to-top').click(function(event){
          event.preventDefault();
          $('.cd-panel__content').animate({
            scrollTop:0
          },'3000');
        });

        // Select all links with hashes
        $('.menu-main-menu a[href*="#"]')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if (
              location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }
          });

        // SCROLLREVEAL.JS
        window.sr = new ScrollReveal();

        function animateProjh2 (el) {
          el.classList.add('is-visible');
        }

        sr.reveal('.project', {
          delay: 0,
          viewFactor: 0.2,
          scale: 1,
          afterReveal: animateProjh2 
        });
        sr.reveal('.project h2', {
          delay: 0,
          easing: 'ease-out',
          viewFactor: 1,
          scale: 1
        });
        sr.reveal('.project-row', {
          delay: 600,
          easing: 'ease-out',
          viewFactor: 0.5,
          scale: 0.75
        });

        sr.reveal('.footer-contact a:first-child', {
          delay: 600,
          easing: 'ease-out',
          viewFactor: 1,
          scale: 1,
          interval: 600,
          origin: 'left',
          distance: '20px'
        });
        sr.reveal('.footer-contact a:last-child', {
          delay: 800,
          easing: 'ease-out',
          viewFactor: 1,
          scale: 1,
          interval: 800,
          origin: 'right',
          distance: '20px'
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        function updateViewportDimensions() {
          var w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
          return { width:x,height:y };
        }
        // setting the viewport width
        var viewport = updateViewportDimensions();

        // EQUAL HEIGHT ELEMENTS ON PROCESS
        equalheight = function(container){
          var currentTallest = 0,
          currentRowStart = 0,
          currentDiv = 0,
          rowDivs = [],
          $el,
          topPostion = 0;
          $(container).each(function() {
            $el = $(this);
            $($el).height('auto');
            topPostion = $el.position().top;

            if (currentRowStart !== topPostion) {
              for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
               rowDivs[currentDiv].height(currentTallest);
              }
              rowDivs.length = 0; // empty the array
              currentRowStart = topPostion;
              currentTallest = $el.height();
              rowDivs.push($el);
            } else {
              rowDivs.push($el);
              currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
              rowDivs[currentDiv].height(currentTallest);
            }
          });
        };

        if( viewport.width >= 900 ) {
          $(window).load(function() {
            equalheight('#home-blog .eq');
            equalheight('.wwdb-row .eqheight');
          });

          $(window).resize(function(){
            equalheight('#home-blog .eq');
            equalheight('.wwdb-row .eqheight');
          });
        }
      }
    },
    // Home page
    'home': {
      init: function() {

        function updateViewportDimensions() {
          var w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0], x = w.innerWidth || e.clientWidth || g.clientWidth, y = w.innerHeight || e.clientHeight || g.clientHeight;
          return { width:x,height:y };
        }
        // setting the viewport width
        var viewport = updateViewportDimensions();

        function showOurTeamBtn(el) {
          var deltime;
          if( viewport.width >= 600 ) {
            deltime = 7000;
          }
          else {
            deltime = 1500;
          }
          setTimeout(
          function() {
            $('.btn-wrap.our-team').delay(deltime).addClass('visible');
          }, 1500);
        }

        //home page
        if( viewport.width >= 768 ) {
          sr.reveal('.strong-man', { delay: 750, scale: 0.7, distance: '200px', mobile: false});
          sr.reveal('#money-shot figcaption', { delay: 1500, scale: 1, origin: 'bottom', distance: '10px', viewFactor: 0.2, mobile: false });
          //sr.reveal('#our-wares', { delay: 750, scale: 1, mobile: false });
          sr.reveal('.portfolio-grid', { scale: 0.9, viewFactor: 0.4, mobile: false});
          sr.reveal('.wwdb-row figure', { duration: 1000, scale: 1, origin: 'left', distance: '200px', easing: 'cubic-bezier(0.6, 0.2, 0.1, 1)', viewFactor: 0.9,
          beforeReveal: showOurTeamBtn
          });
          sr.reveal('.wwdb-row figure figcaption', { delay: 1500, scale: 1, origin: 'bottom', distance: '10px', viewFactor: 0.9, mobile: false });
          sr.reveal('.hang-glider figcaption', { delay: 500, scale: 1, origin: 'bottom', distance: '10px', viewFactor: 0.9, mobile: false });
          sr.reveal('#footer .bg-white', { scale: 1, origin: 'bottom', distance: '50px', viewFactor: 0.3, mobile: false });

        }

        $('#peak-ethos').fullpage({
          verticalCentered:false,
          autoScrolling: false,
          fitToSection: false,
          anchors:['ethos'],
          afterSlideLoad: function( anchorLink, index, slideAnchor, slideIndex){
            $('.nav__item.active').removeClass('active');
            $('.nav__item').eq(slideIndex).addClass('active');
            if (slideIndex === 1) {
              $('.gemelos').addClass('visible');
            }
            else if (slideIndex === 2) {
              $('.dr-small-type').addClass('visible');
            }
            else if (slideIndex === 3) {
              $('.owl-heart').addClass('visible');
            }
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Work page, note the change from about-us to about_us.
    'work': {
      init: function() {
        // JavaScript to be fired on the about us page
        sr.reveal('.cat-cycle', { scale: 1, delay: 1000, viewFactor: 0.4, origin: 'right', distance: '50px', mobile: true});
        sr.reveal('.top-img figcaption', { delay: 1250, scale: 1, origin: 'left', distance: '10px', viewFactor: 0.4, mobile: true });

        // Fire Isotope only when images are loaded
        var $iso = $('.work-container .image-grid');
        $iso.imagesLoaded(function () {
          $iso.isotope({
            layoutMode: 'fitRows',
            itemSelector: '.portfolio-item',
            fitRows: {
              columnWidth: '.grid-sizer',
              gutter: '.gutter-sizer'
            }
          });
        });

        // filter items on button click
        $('.filter-button-group').on( 'click', 'button', function() {
          $('.filter-button-group button').removeClass('active');
          $(this).addClass('active');
          var filterValue = $(this).attr('data-filter');
          $iso.isotope({ filter: filterValue });
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
